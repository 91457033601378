<template>
    <div class="resetPasswordRequest">
        <b-container>
            <b-card bg-variant="light">
                <h2>Reset Password</h2>
                    <b-form id="resetPwdForm" @submit.stop.prevent="onSubmit">
                        <b-form-group horizontal :label-cols="5" breakpoint="md" label="Email" label-for="email">
                            <b-form-input id="email" name="email" v-model="email" type="email" required></b-form-input>
                        </b-form-group>
                        <b-alert :show="responses != ''"  variant="success">{{responses}}</b-alert>
                        <b-alert :show="errors != ''"  variant="danger">{{errors}}</b-alert>
                        <b-button type="submit" variant="primary" v-bind:disabled="!submittable()">Submit</b-button>
                    </b-form>
            </b-card>
        </b-container>
    </div>
</template>

<script>
import axios from "axios";

export default {
	name: "ResetPasswordRequest",
	data() {
		return {
			responses: '',
			errors: '',
			email: ''
		};
	},
	methods: {
		onSubmit() {
			var request = {
				'email': this.email
			};

			axios
				.post('/api/resetPasswordRequest', request)
				.then((response) => {
					if (response.data.error != "" && response.data.error != null) {
						this.errors = response.data.error;
						this.responses = "";
					}
					if (response.data.success != "" && response.data.success != null) {
						this.responses = response.data.success;
						this.errors = "";
					}
				})
				.catch((e) => {
					this.errors = e;
				});
		},
		submittable() {
			return this.email != '';
		}
	},
};
</script>