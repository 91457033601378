<template>
<div class="authentication">
    <b-form-group horizontal label-class="text-sm-right" :label-cols="5" breakpoint="md" id="username" :label="$props.usernameLabel" label-for="username">
        <b-form-input id="username" type="text" v-model="auth.username" required placeholder="Enter Username"></b-form-input>
    </b-form-group>
    <b-form-group horizontal label-class="text-sm-right" :label-cols="5" breakpoint="md" :label="$props.passwordLabel" label-for="password">
        <b-form-input id="password" type="password" name="password" v-model="auth.password" required ref="password" placeholder="Enter Password"></b-form-input>
    </b-form-group>
</div>
</template>

<script>
export default {
    name: 'UserAuthentication',
    props: {
        usernameLabel: String,
        passwordLabel: String,
        value: Object
    },
    computed: {
        auth: {
            get() {
                return this.value;
            },
            set(auth) {
                this.$emit('input', auth)
            }
        }
    }
}
</script>