import VueRouter from 'vue-router';
import CreateTenant from '@/components/CreateTenant.vue'
import CreateTenantAlias from '@/components/CreateTenantAlias.vue'
import DeleteTenant from '@/components/DeleteTenant.vue'
import DeleteTenantAlias from '@/components/DeleteTenantAlias.vue'
import ResetPasswordRequest from '@/components/ResetPasswordRequest.vue'
import DeleteSuperUser from '@/components/DeleteSuperUser.vue'
import DisableSuperUserMfa from '@/components/DisableSuperUserMfa.vue'
import EnableSuperUserMfa from '@/components/EnableSuperUserMfa.vue'


export default new VueRouter({
	routes: [
		{
			path: '/createTenant',
			name: 'createTenant',
			component: CreateTenant
		},
		{
			path: '/createTenantAlias',
			name: 'createTenantAlias',
			component: CreateTenantAlias
		},
		{
			path: '/deleteTenant',
			name: 'deleteTenant',
			component: DeleteTenant
		},
		{
			path: '/deleteTenantAlias',
			name: 'deleteTenantAlias',
			component: DeleteTenantAlias
		},
		{
			path: '/resetPasswordRequest',
			name: 'resetPasswordRequest',
			component: ResetPasswordRequest
		},
		{
			path: '/deleteSuperUser',
			name: 'deleteSuperUser',
			component: DeleteSuperUser
		},
		{
			path: '/disableSuperUserMfa',
			name: 'disableSuperUserMfa',
			component: DisableSuperUserMfa
		},
		{
			path: '/enableSuperUserMfa',
			name: 'enableSuperUserMfa',
			component: EnableSuperUserMfa
		}
	],
	mode: 'hash'
});