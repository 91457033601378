export default {
	isComplexEnough: function (password) {
		let hasLowercase = /[a-z]/.test(password);
		let hasUppercase = /[A-Z]/.test(password);
		let hasNumbers = /[0-9]/.test(password);
		let hasSymbols = /[*.!@#$%^&(){}[\]:;<>,.?\\/~_+\-=|]/.test(password);
		let isLongEnough = password.length >= 8;

		let errorMsg = [];
		if (!hasLowercase) {
			errorMsg.push('A lowercase letter is required.');
		}

		if (!hasUppercase) {
			errorMsg.push('An uppercase letter is required.');
		}

		if (!hasNumbers) {
			errorMsg.push('A number is required.');
		}

		if (!hasSymbols) {
			errorMsg.push('A symbol is required.');
		}

		if (!isLongEnough) {
			errorMsg.push('Password is too short.');
		}

		this.passwordErrors = errorMsg.join('\n');

		return hasLowercase && hasUppercase && hasNumbers && hasSymbols && isLongEnough;
	},

	md5HashPwd: function (Forge, password) {
		const md = Forge.md.md5.create()
		md.update(password);
		return md.digest().toHex();
	},

	isValidVerificationCode: function (code) {
		return code.match(/^[0-9]{6}$/);
	}
}