<template>
  <div class="enableSuperUserMfa">
      <b-container>
            
    <b-card bg-variant="light" class="text-center">
        <h2>Enable Super User MFA</h2>
        <b-form id="requestForm" @submit.prevent="sendRequest">
                <UserAuthentication v-model="auth" usernameLabel="Username" passwordLabel="Password"/>
                <hr class="solid">
                <b-form-group horizontal label-class="text-sm-right" :label-cols="5" breakpoint="md" id="superUserEmail" label="Super User Email" label-for="superUserEmail">
                    <b-form-input id="superUserEmail" type="text" v-model="request.superUserEmail" required placeholder="Enter Super User Email"></b-form-input>
                </b-form-group>
                <b-button type="submit" variant="primary">Submit</b-button>
            </b-form>
            <div class="alert alert-danger" v-show="veeErrors.any()">
                <div v-if="veeErrors.has('Tenant Name')">{{ veeErrors.first('Tenant Name') }}</div>
            </div>
            <b-alert :show="responses != ''"  variant="success">{{responses}}</b-alert>
            <b-alert :show="errors != ''"  variant="danger">{{errors}}</b-alert>
        </b-card>
      </b-container>
    </div>
</template>


<script>
import Forge from "node-forge";
import axios from 'axios'
import UserAuthentication from '@/components/UserAuthentication.vue';
import helpers from '@/helpers.js';

export default {
  name: 'EnableSuperUserMfa',
  components: {
      UserAuthentication
    },
  data ()  {
    return {
        responses: '',
        errors: '',
        auth: {
            username: '',
            password: ''
        },
        request: {
            superUserEmail: ''
        }
    }
  },
  methods:{
      sendRequest: function(event){
          var request = {
              auth: {
                username: this.auth.username,
                password: helpers.md5HashPwd(Forge, this.auth.password),
            },
            email: this.request.superUserEmail
          };

        axios.post('/api/mfa/enable', request)
            .then(response => {
                if (response.data.error != "" && response.data.error != null) {
                    this.errors = response.data.error;
                    this.responses = '';
                }
                if (response.data.success != "" && response.data.success != null) {
                    this.responses = response.data.success;
                    this.errors = '';
                    this.auth.username = "";
                    this.auth.password = "";
                    this.request.superUserName = "";
                }
            }).catch(e => {
                this.errors = e;
            });
    }
    }
}
</script>
