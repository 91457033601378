<template>
    <div class="partner-groups">
        <b-form-select id="accessGroupName" v-model="value.accessGroup">
            <option v-if="!this.emptyOption" value="" disabled selected>Select partner group</option>
            <option v-for="accessGroup in accessGroups" v-bind:value="accessGroup.key" :key="accessGroup.name">{{accessGroup.name}}</option>
        </b-form-select>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'PartnerGroups',
    props: {
        emptyOption: Boolean,
        value: Object
    },
    data() {
        return {
            accessGroups: []
        }
    },
    created() {
            this.getAccessGroupList();
    },
    methods: {
        getAccessGroupList: function() {
            axios.get("/api/accessGroups").then(response => {
                this.accessGroups = response.data;
                if (this.emptyOption) {
                    this.accessGroups.unshift({key: '', name: 'N/A'});
                }
            }).catch(e => {
                this.errors = e;
            });
        }
    },
    computed: {
        auth: {
            get() {
                return this.value;
            },
            set(accessGroup) {
                this.$emit('input', accessGroup)
            }
        }
    }
}
</script>